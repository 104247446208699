@font-face {
    font-family: 'RedHatDisplay';
    src: url('../public/fonts/RedHatDisplay-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: RedHatDisplay, sans-serif;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    background-color: #f0f0f0 !important;
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Style for dropdown links */
.nav-link {
    display: block;
    text-align: center;
    /* padding: 14px 16px;*/
    text-decoration: none;
/*    color: deepskyblue;*/
    min-width: 128px;
}

/* Change color on hover for links */
.nav-link:hover {
    background-color: #A9A9A9;
    cursor: pointer;
}